import React, { PureComponent } from 'react'

import ProductService from '../services/ProductService'
import CommentService from '../services/CommentService'
import PromotionBar from '../components/Store/Product/PromotionBar'
import LoadingModal from '../components/common/LoadingPage/LoadingModal'
import ProductModal from '../components/Store/Cart/ProductModal'
import ProductDrawer from '../components/Store/Cart/ProductDrawer'
import FlashPromotionBar from '../components/Store/Product/FlashPromotionBar'
import ProductPromotionBar from '../components/Store/Product/ProductPromotionBar'
import { getVariantsByVariantOptions } from '../utils/Store/productUtils'
import withCartAndPlugins from './withCartAndPlugins'
import { hoistHocStatic } from '@flamingo_tech/funkgo'
import { setDefaultSelectedOptionValues, isVariantsDisabledByVariantOptions } from '../utils/Store/productUtils'
import { DynamicProductVariant } from '../npm/FunkGoModel/ProductModel'
import { rem as px2rem } from '../utils/remUtils'


/* ---------------------------------- */
const withQuickAdd = (Component, { eventCategory } = {}, staticProps) => {

  const displayName = `withQuickAdd(${Component.displayName || Component.name})`

  class WrappedComponent extends PureComponent {
    state = {
      product: {},
      showSpecSelectionModal: false,
      productDetailFetched: false,
      comment: {},
      curImageIndex: 0,
      selectedVariantInfo: {},
      curImages: [],
      showLoadingModal: false
    }

    productService = new ProductService(this.props.$http)
    commentService = new CommentService(this.props.$http)
    _atcTriggerRef = null

    // need return promise
    handleQuickAdd = ({ product, quickAddInfo, selectedVariantInfo, refInfo }) => {
      const { triggerRef = null } = refInfo || {}

      this._atcTriggerRef = triggerRef

      if (quickAddInfo) {
        const { product, comment, selectedVariantInfo, renderLink } = quickAddInfo
        const productWithoutVideo = {
          ...(product || {}),
          images: ((product || {}).images || []).filter(item => item.type !== 'VIDEO')
        }

        this.setState({
          product: productWithoutVideo,
          productDetailFetched: true,
          showSpecSelectionModal: true,
          comment: comment || {},
          selectedVariantInfo: selectedVariantInfo || {},
          renderLink,
        })
        this.props.onQuickAddModalOpened && this.props.onQuickAddModalOpened()
        this.getCurImagesByOption(selectedVariantInfo, null, productWithoutVideo)
        return this.createDeferredPromise()
      }

      let { handle } = product

      if (!handle) {
        return
      }

      this.setState({
        showLoadingModal: true
      })

      const fetchDynamicProductReq = this.productService.fetchDynamicProduct(handle).catch(() => null)
      const fetchProductSizeInfoReq = this.productService.fetchProductSizeInfo(handle).catch(() => {})

      return Promise.all([this.productService.fetchProduct(handle), fetchDynamicProductReq, fetchProductSizeInfoReq])
        .then(([product, dynamicProduct, productSizeInfo]) => {

          const { mainVariation: oldMainVariation, variations  } = product

          let updatedProduct = {
            ...product,
            ...productSizeInfo,
            images: (product.images || []).filter(item => item.type !== 'VIDEO')
          }

          let updatedVariations = [...variations]

          if (dynamicProduct) { // update data if has
            const { availableForSale, mainVariation, variationsMap } = dynamicProduct

            updatedVariations = variations.map(variation => ({
              ...variation,
              ...new DynamicProductVariant(variationsMap[variation.id])
            }))

            updatedProduct = {
              ...updatedProduct,
              availableForSale,
              variations: updatedVariations
            }

            if (availableForSale && updatedVariations.filter(variation => variation.availableForSale).length === 0) {
              updatedProduct.availableForSale = false
            }


            if (!updatedProduct.availableForSale) {
              this.props.$track.event('Product', 'outOfStock', updatedProduct.handle)
            }

            if (oldMainVariation.id === mainVariation.id) {
              updatedProduct.mainVariation = Object.assign({}, oldMainVariation, new DynamicProductVariant(mainVariation))
            }
          }

          const disabled = isVariantsDisabledByVariantOptions({ ...selectedVariantInfo }, updatedVariations)
          const selectedVariants = selectedVariantInfo || setDefaultSelectedOptionValues(updatedProduct)

          this.setState({
            product: updatedProduct,
            productDetailFetched: true,
            showSpecSelectionModal: true,
            showLoadingModal: false,
            selectedVariantInfo: disabled ? setDefaultSelectedOptionValues(updatedProduct) : selectedVariants,
          })

          this.props.onQuickAddModalOpened && this.props.onQuickAddModalOpened()
          this.getCurImagesByOption({}, null, updatedProduct)

          this.commentService.fetchProductCommentInfo(handle).then(comment => {
            this.setState({
              comment: {
                ...comment,
                overallCount: comment.count
              }
            })
          })

        })
        .catch(() => {
          this.setState({
            showLoadingModal: false
          })
        })
    }

    createDeferredPromise() {
      return new Promise((resolve, reject) => {
        this.deferred = { resolve, reject }
      })
    }

    resolveDeferred(payload) {
      if (this.deferred) {
        this.deferred.resolve(payload)
      }
      this.deferred = null
    }

    rejectDeferred(err) {
      if (this.deferred) {
        this.deferred.reject(err)
      }
      this.deferred = null
    }

    closeSpecSelectionModal = () => {
      this.resetState()
    }

    isEqual = (options1, options2) => {
      const options1Value1 = options1.map(i => i.value).join('/')
      const options1Value2 = options2.map(i => i.value).join('/')
      return options1Value1 === options1Value2
    }

    handleSpecSelectionConfirm = ({ selectedVariant, selectedQuantity }) => {
      if (this.props.isUpdateMode) {
        return new Promise((resolve, reject) => {

          const isEqualVariant = this.isEqual(selectedVariant.selectedOptions, this.props.selectedOptions)

          if (isEqualVariant) {
            this.resetState()
            resolve()
            return
          }

          const replaceFn = this.props.replaceVariant || this.props.$replaceVariant

          replaceFn({ variant: selectedVariant, itemId: this.props.product.itemId })
            .then(() => {
              this.resetState(true)
              resolve()
            })
            .catch(error => {
              this.props.$toastError(error.message)
              this.resetState()
              reject(error)
            })

          this.resolveDeferred({ variant: selectedVariant, quantity: selectedQuantity })

        })
      } else {
        const addFn = this.props.addVariant || this.props.$addVariant // self atc function || default atc function

        return new Promise((resolve, reject) => {
          addFn({
            variant: selectedVariant,
            quantity: selectedQuantity,
            product: this.state.product || {},
            needFetchFullAfterAddCart: this.props.needFetchFullAfterAddCart
          }).then(() => {

            this.props.updatePrice && this.props.updatePrice()
            this.resetState(true)

            if (!this.props.hideAtcSuccessEffect) {
              setTimeout(() => {
                if (!this.props.$store.startAtcParabola({
                  startRef: this._atcTriggerRef
                })) {
                  this.props.$toastSuccess({
                    content: this.props.$i18n.transl('core.cart.addSuccess'),
                    duration: 1500
                  })
                }
              })
            }

            resolve()
          }).catch(error => {
            this.props.$toastError(error.message)
            this.resetState()
            reject(error)
          })

          this.resolveDeferred({ variant: selectedVariant, quantity: selectedQuantity })
        })
      }
    }

    resetState = (isAtcSucceed) => {
      this.setState({
        product: {},
        productDetailFetched: false,
        showSpecSelectionModal: false,
        selectedVariantInfo: {}
      })
      this.props.onQuickAddModalClosed && this.props.onQuickAddModalClosed(isAtcSucceed)
    }

    getSortedProductImages = (images, options) => {
      const tagImages = []

      options.forEach((option) => {
        if (option.name === 'Color') {
          option.values.forEach(opt => {
            images.forEach(image => {
              const { tag } = image
              if (tag && tag.optionValue && tag.optionValue === opt) {
                tagImages.push(image)
              }
            })
          })
        }
      })

      // filter untagImages
      const untagImages = images.filter(image => tagImages.indexOf(image) <= -1)

      return [...tagImages, ...untagImages]
    }

    getCurImagesByOption = (currentVariants = {}, force, product) => {
      let imgs = []
      const { images = [], options = [] } = product || this.state.product || {}
      const hasSelected = Object.keys(currentVariants).length > 0

      const selectedOption = hasSelected ? currentVariants.Color : this.state.selectedVariantInfo.Color

      if (currentVariants.Color  === this.state.selectedVariantInfo.Color  && !force) {
        return
      }

      imgs = this.getSortedProductImages(images, options)

      for (let i = 0; i < imgs.length; i++) {
        const { tag } = imgs[i]
        if (tag && tag.optionValue && tag.optionValue === selectedOption) {
          this.setState({
            curImageIndex: i
          })
          break
        }
      }


      this.setState({
        curImages: imgs
      })
    }

    onOptionSelectedChanged = (data) => {
      this.setState({
        selectedVariantInfo: data
      })
      this.getCurImagesByOption(data)
    }

    renderPromotionBar = product => {
      const { meta } = product

      if (meta && meta.feature && meta.feature.promotionBar) {
        return (
          <ProductPromotionBar {...meta.feature.promotionBar} />
        )
      }
      const { msrp, price } = product.mainVariation || {}
      const hasFlashDiscount = !!(msrp && msrp > price && product.isFlashSale)

      if (hasFlashDiscount) {
        const isDesktop = this.props.$detector.isDesktop()
        const style = { width: px2rem(isDesktop ? 409 : 375) }
        return <div style={style}><FlashPromotionBar product={product} /></div>
      }

      const bestCoupon = this.props.$store
        ? this.props.$store.couponHub.pickBestForProduct(product)
        : undefined

      if (!bestCoupon) {
        return null
      }

      const { productDetail } = bestCoupon.meta.layout || {}
      if (productDetail && productDetail.is === 'PromotionBar') {
        return (
          <PromotionBar
            $i18n={this.props.$i18n}
            promotion={bestCoupon}
            {...productDetail.options}
          />
        )
      } else {
        return null
      }
    }

    generateCartInfo = () => {
      const { product } = this.state
      const cart = {
        selectedVariant: null,
        selectedOptions: this.state.selectedVariantInfo,
        selectedQuantity: 1
      }

      if (Object.keys(this.state.selectedVariantInfo).length === (product.options && product.options.length)) {
        const variants = getVariantsByVariantOptions(this.state.selectedVariantInfo, product.variations)
        cart.selectedVariant = variants[0]
      }
      return cart
    }

    generateVariantWithoutChosen = (selectedVariants, variants) => {
      return variants.filter(variant => {
        return !selectedVariants[variant.name]
      })[0]
    }

    handleAddToCartClick = () => {
      // directly add to cart without open modal when there is default variant
      const { product } = this.state
      if (product.availableForSale) {
        if (this.state.selectedVariantInfo) {
          const selectedVariants = this.state.selectedVariantInfo
          const cart = this.generateCartInfo()

          if (cart.selectedVariant) {
            return this.handleSpecSelectionConfirm(cart)
          } else {
            this.props.$toastWarning({
              content: this.props.$i18n.transl('core.product.chooseOption', {
                option: this.generateVariantWithoutChosen(selectedVariants, this.state.product.options).displayName
              }),
              dialogId: 'product-add'
            })
            return Promise.resolve()
          }
        }
      } else {
        // out of stock
        this.props.$router.navigateToMessengerBot('onshelfSubscription', product.handle, () => { })
        return Promise.resolve()
      }
    }

    hasOneSelectedVariant = (options) => {

      return Object.keys(this.state.selectedVariantInfo).length === options.length
    }

    fetData = (product = {}, showSpecSelectionModal) => {

      if (showSpecSelectionModal && product.id) {
        const { feature = {} } = product.meta || {}
        const { tag } = feature

        const curSelectedVariants = getVariantsByVariantOptions(this.state.selectedVariantInfo, product.variations)
        const variantInfo = this.hasOneSelectedVariant(product.options) ? curSelectedVariants[0] : product.mainVariation
        return {
          tag: tag,
          product: product,
          comment: this.state.comment,
          variantInfo: variantInfo,
          curImageIndex: this.state.curImageIndex,
          curImages: this.state.curImages.length > 0 ? this.state.curImages : product.images,
          selectedVariants: this.state.selectedVariantInfo,
          onOptionSelectedChanged: this.onOptionSelectedChanged,
          renderPromotionBar: this.renderPromotionBar,
          minSliderWrapper: true,
          renderLink: this.state.renderLink
        }
      }
      return false
    }

    render() {
      const isDesktop = this.props.$detector.isDesktop()
      const renderProps = this.fetData(this.state.product, this.state.showSpecSelectionModal)
      const ProductDrawerComponent = this.props.CustomizedProductDrawer || (isDesktop ? ProductModal : ProductDrawer)

      return (
        <>
          <LoadingModal hide={!this.state.showLoadingModal}></LoadingModal>
          <Component
            {...this.props}
            onQuickAdd={this.handleQuickAdd}
            eventCategory={eventCategory}
            resetState={this.resetState}
          ></Component>
          {
            this.state.showSpecSelectionModal && renderProps &&
            <ProductDrawerComponent
              {...renderProps}
              headerTitle={isDesktop && this.props.$i18n.transl('core.cart.editSelection')}
              onAddToCartClick={this.handleAddToCartClick}
              onClose={this.closeSpecSelectionModal}
              {...renderProps.product}
              onDrawerClose={() => this.setState({ showSpecSelectionModal: false })}
              isUpdateMode={this.props.isUpdateMode}
            />
          }
        </>
      )
    }
  }

  WrappedComponent.displayName = displayName
  hoistHocStatic(WrappedComponent, Component, staticProps)

  return withCartAndPlugins(WrappedComponent)
}

export default withQuickAdd
